/* You can add global styles to this file, and also import other style files */
dp-date-picker.dp-material {
    display: block !important;
}
.page-item .page-link{
    cursor: pointer;
}
.nav-item .nav-link{
    cursor: pointer;
}

li.page-item{
    cursor: pointer;
}
div .autocomplete-container{
    z-index:9999;
    position: absolute;
    background: white;
    border: 1px solid black;
}
div .autocomplete-option{
    padding-left: 10px;
    padding-right: 10px;
}
div .autocomplete-option:hover{
    cursor: default;
    background: #007bff;
    color: white;
}